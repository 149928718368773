import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/home/Home.vue'),
    children: [
      {
        path: '',
        name: 'landing',
        component: () => import('@/views/home/Landing.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: 'availability',
        name: 'availability',
        component: () => import('@/views/home/Availability.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: 'user_register',
        name: 'user_register',
        component: () => import('@/views/home/UserRegister.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: 'administration',
        name: 'administration',
        component: () => import('@/views/home/Administration.vue'),
        meta: {
          layout: 'blank',
        },
      },
    ],
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/HomeDashboard.vue'),
  },

  {
    path: '/account_settings',
    name: 'account_settings',
    component: () => import('@/views/account_settings/AccountSettings.vue'),
  },

  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('@/views/configuration/Configuration.vue'),
  },
  {
    path: '/stay',
    name: 'stay',
    component: () => import('@/views/stay/Stay.vue'),
  },
  {
    path: '/staff',
    name: 'staff',
    component: () => import('@/views/staff/Staff.vue'),
  },
  {
    path: '/fund_list',
    name: 'fund_list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/treasury/Fund.vue'),
  },
  {
    path: '/stay_payment',
    name: 'stay_payment',
    component: () => import('@/views/stay_payment/Payment.vue'),
  },
  {
    path: '/customer',
    name: 'client',
    component: () => import('@/views/customer/Customer.vue'),
  },
  {
    path: '/stock',
    name: 'stock',
    component: () => import('@/views/supplies/Stocks.vue'),
  },
  {
    path: '/flux',
    name: 'flux',
    component: () => import('@/views/supplies/Flux.vue'),
  },
  {
    path: '/repport',
    name: 'repport',
    component: () => import('@/views/repport/Repport.vue'),
  },
  {
    path: '/fund_transaction',
    name: 'fund_transaction',
    component: () => import('@/views/treasury/FundTransaction.vue'),
  },
  {
    path: '/compta_fund/:id_fund/:label/:amount',
    name: 'compta_fund',
    component: () => import('@/views/compta_fund/ComptaFund.vue'),
  },
  {
    path: '/bar_invoice',
    name: 'bar_invoice',
    component: () => import('@/views/treasury/FundTransaction.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/room/list',
    name: 'room-list',
    component: () => import('@/views/rooms/RoomList.vue'),
  },
  {
    path: '/room/occuption-shedule',
    name: 'room-occupation-shedule',
    component: () => import('../views/rooms/RoomOccupancySchedule.vue'),
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('currentUser') || to.name === 'landing' || to.name === 'administration' || to.name === 'availability' || to.name === 'user_register') next()
  else {
    window.location = '/'
  }
})

//

export default router
