import axios from 'axios'
// eslint-disable-next-line import/extensions
import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/dist/sweetalert2.min.css'

axios.interceptors.request.use(
  async config => {
    // console.log(config)
    // console.log(localStorage.getItem('accessToken'))

    // Accept: 'application/json',
    //     'Content-Type': 'application/x-www-form-urlencoded',
    const token = localStorage.getItem('accessToken')
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      }
    }

    return config
  },
  error => {
    Promise.reject(error)
  },
)
axios.interceptors.response.use(response => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (response.data.message) {
    Swal.fire({
      icon: 'success',
      title: response.data.message,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    })
  }

  return response
}, error => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // console.log(error.response)
  if (error.response.status === 400 && error.response.data.message) {
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    })
  }
  if (error.response.status === 401 && error.response.data.message) {
    // alert('ok')
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    })
  }
  if (error.response.status === 401 && !error.response.data.message) {
    window.location = '/administration'
  }
  if (error.response.status === 422 && Object.values(error.response.data)[0][0]) {
    Swal.fire({
      icon: 'error',
      title: Object.values(error.response.data)[0][0],
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    })
  }
  if (error.response.status === 500 && error.response.data.message) {
    // alert('ok')
    Swal.fire({
      icon: 'error',
      title: error.response.data.message,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    })
  }

  // http://localhost:8080
  // if (error.response.status === 500) {
  //   Swal.fire({
  //     icon: 'error',
  //     title: error.response.data.message,
  //     toast: true,
  //     position: 'top-end',
  //     showConfirmButton: false,
  //     timer: 3000,
  //     timerProgressBar: true,
  //     didOpen: toast => {
  //       toast.addEventListener('mouseenter', Swal.stopTimer)
  //       toast.addEventListener('mouseleave', Swal.resumeTimer)
  //     },
  //   })
  // }

  return Promise.reject(error)
})

export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$axios = axios
  },
}
