require('./overrides.scss')
// '#9155FD',

export default {
  theme: {
    themes: {
      light: {
        primary: '#C11E1F',

        // '#FF5F45',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        white: '#FFFFFF',
      },
      dark: {
        primary: '#C11E1F',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        white: '#FFFFFF',
      },
    },
  },
}
