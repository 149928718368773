// eslint-disable-next-line import/extensions
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import CoolLightBox from 'vue-cool-lightbox'
import VueViewer from 'v-viewer'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from './plugins/axios'
import endpoint from './plugins/endpoint'
import Jquery from './plugins/jquery'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import 'viewerjs/dist/viewer.css'
Vue.config.productionTip = false
Vue.use(VueViewer)
Vue.use(CoolLightBox)
Vue.use(axios)
Vue.use(endpoint)
Vue.use(Jquery)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
