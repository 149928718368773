const apiUrl = process.env.VUE_APP_API_URL
export default {
  LoginUser: `${apiUrl}auth/login`,
  CreateStay: `${apiUrl}stay/register`,
  CreateCustomer: `${apiUrl}customers/register`,
  UpdateCustomer: `${apiUrl}customers/update`,
  LoadCustomer: `${apiUrl}customers`,
  LoadStay: `${apiUrl}stay`,
  UpdateStay: id => `${apiUrl}stay/update/${id}`,
  CancelStay: id => `${apiUrl}stay/cancel/${id}`,
  ShortenStay: id => `${apiUrl}stay/shorten/${id}`,
  ShowStayInvoice: `${apiUrl}stay/show_invoice`,
  ChangeStayCustomer: id => `${apiUrl}stay/change/${id}/customer`,
  ChangeStayLeavingDate: id => `${apiUrl}stay/change/${id}/leaving-date`,
  ChangeStayRoom: id => `${apiUrl}stay/change/${id}/room`,
  CloseStay: id => `${apiUrl}stay/change/${id}/close`,
  LoadStayPayOffFalse: `${apiUrl}stay/payofffalse`,
  LoadStock: `${apiUrl}stock`,
  LoadRoomAvaible: `${apiUrl}room_avaible`,
  CreateRoomType: `${apiUrl}rooms/create/type`,
  CreateNewCustomer: `${apiUrl}newCustomer`,
  NotifyNewCustomer: `${apiUrl}newCustomer/send/notify`,
  CreateStayType: `${apiUrl}stay/type/create`,
  LoadStayType: `${apiUrl}stay/type`,
  CurrentUserInfos: `${apiUrl}staff/get_infos`,
  LogoutUser: `${apiUrl}auth/logout`,
  CreateApartment: `${apiUrl}apartments/create`,
  LoadApartment: `${apiUrl}apartments`,
  CreateRoom: `${apiUrl}rooms/create`,
  CreateEquipment: `${apiUrl}equipments/register`,
  CreateFunction: `${apiUrl}functions/create`,
  CreateSupply: `${apiUrl}stock/supplies/create`,
  LoadSupplies: `${apiUrl}stock/supplies`,
  CreateStock: `${apiUrl}stock/create`,
  CreateService: `${apiUrl}services/create`,
  CreateStaff: `${apiUrl}staff/create`,
  HomeRepport: `${apiUrl}repport/home`,
  CreateAccountType: `${apiUrl}accounts/type/create`,
  CreateAccount: `${apiUrl}accounts/create`,
  UpdateUser: `${apiUrl}accounts/users/update`,
  LoadAccountType: `${apiUrl}accounts/type`,
  LoadRoom: `${apiUrl}rooms`,
  LoadRoomType: `${apiUrl}rooms/list/type`,
  LoadCited: `${apiUrl}cited`,
  LoadOffice: `${apiUrl}functions`,
  LoadStaff: `${apiUrl}staff`,
  UpdateStaff: `${apiUrl}staff/update`,
  LoadRoute: `${apiUrl}routes`,
  LoadService: `${apiUrl}services`,
  ModifiedCited: `${apiUrl}cited/update`,
  CreatePaymentType: `${apiUrl}treasury/payment/type/create`,
  LoadPaymentType: `${apiUrl}treasury/payment/type`,
  LoadPayment: `${apiUrl}treasury/payment`,
  CreatePayment: `${apiUrl}treasury/payment/create`,
  LoadStayPayment: `${apiUrl}stay/payment`,
  CreateStayPayment: `${apiUrl}stay/payment/create`,
  CreatFund: `${apiUrl}treasury/fund/create`,
  CreateFundType: `${apiUrl}treasury/fund/type/create`,
  LoadFund: `${apiUrl}treasury/fund`,
  LoadPaymentByFund: `${apiUrl}treasury/fund/payments`,
  LoadFundType: `${apiUrl}treasury/fund/type`,
  LoadUsers: `${apiUrl}accounts/users`,
  FindUser: id => `${apiUrl}accounts/user/find/${id}`,
  LoadFlux: `${apiUrl}flux`,
  CreateFlux: `${apiUrl}flux/create`,

  //  ***************** modification*******************
  ///**********Bilan*******
  LoadRepportMonth: `${apiUrl}repport/month`,
  LoadRepportInterval: `${apiUrl}repport/interval`,
//  ***************** modification*******************
  UpdateRoomType: `${apiUrl}rooms/update/type`,
  UpdateRoom: id => `${apiUrl}rooms/update/${id}`,
  DeleteRoomType: id => `${apiUrl}rooms/delete/type/${id}`,
}
