<template>
  <!--  mini-variant-->
  <!--  mini-variant-width="90px"-->
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logos/logo.svg')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">CITE Ste.AUGUSTINE</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link :disabled="false" title="Tableau de bord" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>
      <nav-menu-section-title title="INTERFACE UTILISATEUR"></nav-menu-section-title>
      <nav-menu-link v-if="userInfos.office_id === 1 || userInfos.office_id === 4" title="Séjours" :to="{ name: 'stay' }" :icon="icons.mdiHomeVariant"></nav-menu-link>
      <nav-menu-link v-if="userInfos.office_id === 1" title="Personnel" :to="{ name: 'staff' }" :icon="icons.mdiHomeVariant"></nav-menu-link>
      <nav-menu-link v-if="userInfos.office_id === 1 || userInfos.office_id === 4" title="Clients" :to="{ name: 'client' }" :icon="icons.mdiHomeVariant"></nav-menu-link>
      <nav-menu-group v-if="userInfos.office_id !== 4" title="Trésorerie" :icon="icons.mdiFileOutline">
        <nav-menu-link title="Caisse" :to="{ name: 'fund_list' }"></nav-menu-link>
        <nav-menu-link title="Versement" :to="{ name: 'fund_transaction' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group v-if="userInfos.office_id !== 4" title="Bilan" :icon="icons.mdiFileOutline">
        <nav-menu-link title="Statistique" :to="{ name: 'repport' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group v-if="userInfos.office_id !== 4" title="Paiement" :icon="icons.mdiFileOutline">
        <nav-menu-link title="Paiement de Sejour" :to="{ name: 'stay_payment' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Approvisionnement" :icon="icons.mdiFileOutline">
        <nav-menu-link title="Flux" :to="{ name: 'flux' }"></nav-menu-link>
        <nav-menu-link title="Stock" :to="{ name: 'stock' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Chambres" :icon="icons.mdiFileOutline">
        <nav-menu-link title="Lister" :to="{ name: 'room-list' }"></nav-menu-link>
        <nav-menu-link title="Planning d'occupation" :to="{ name: 'room-occupation-shedule' }"></nav-menu-link>
        <nav-menu-link title="Hors Services" :to="{ name: 'error-404' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-section-title  title="PARAMÉTRAGE"></nav-menu-section-title>
      <nav-menu-link v-if="userInfos.office_id === 1" title="Configuration" :to="{ name: 'configuration' }" :icon="icons.mdiCogOutline"></nav-menu-link>
      <nav-menu-link
        title="Mon compte"
        :to="{ name: 'account_settings' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiCogOutline,
  mdiHomeVariant,
} from '@mdi/js'
import * as components from 'vuetify'

import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
    setup() {
     let userInfos =  JSON.parse(localStorage.getItem('currentUserInfos'));
     if (!userInfos){
       userInfos = JSON.parse(localStorage.getItem('currentUserInfos'));
     }
     return {
       icons: {
         mdiHomeOutline,
         mdiAlphaTBoxOutline,
         mdiEyeOutline,
         mdiCreditCardOutline,
         mdiTable,
         mdiFileOutline,
         mdiFormSelect,
         mdiAccountCogOutline,
         mdiCogOutline,
         mdiHomeVariant,
       },
       userInfos,
     }
   },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
